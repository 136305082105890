import {Component, HostBinding, Input, ViewEncapsulation} from '@angular/core';


/**
 * Avatar
 *
 * Can contain elements:
 * - etc...
 */
@Component({
    selector: 'mark6-avatar',
    exportAs: 'mark6Avatar',
    template: `<ng-content select="mark6-avatar-content"></ng-content>

<ng-content select="mark6-avatar-content-edit" *ngIf="editMode"></ng-content>

<div class="mark6-upload-overlay" *ngIf="editMode"></div>

<ng-content select="mark6-avatar-image"></ng-content>

<ng-content select="mark6-avatar-upload"></ng-content>

<progress [value]="uploadProgress" max="100" *ngIf="uploadProgress && editMode"></progress>
`,
    styles: [`.mark6-avatar{display:block;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;position:relative}@media only screen and (min-width:1280px){.mark6-avatar:hover:not(.uploading) .mark6-avatar-upload{opacity:1;cursor:pointer;background-color:rgba(0,0,0,.64)}.mark6-avatar.uploading .mark6-upload-overlay,.mark6-avatar:hover .mark6-upload-overlay{background-color:rgba(255,255,255,.48)}}.mark6-avatar .mark6-upload-overlay{width:100%;height:100%;top:0;left:0;position:absolute;z-index:200;transition-duration:.4s;background-color:rgba(255,255,255,0);transition-timing-function:cubic-bezier(.25,.8,.25,1);transition-property:background-color}.mark6-avatar img{z-index:100;position:relative}.mark6-avatar progress{width:100%;height:100%;top:0;left:0;position:absolute;z-index:250;opacity:.64;-webkit-transform:rotate(-90deg);transform:rotate(-90deg);border:none;-webkit-appearance:none}.mark6-avatar progress[value]::-webkit-progress-value{background:#e53935}.mark6-avatar progress[value]::-moz-progress-bar{background:#f8c7c6}.mark6-avatar progress[value]::-webkit-progress-bar{background:#f8c7c6}`],
    encapsulation: ViewEncapsulation.None
})
export class Mark6AvatarComponent {

    @HostBinding('class') classes = 'mark6-avatar';

    @HostBinding('class.uploading')
    get isUploading() {
        return this.uploadProgress > 0;
    }

    @Input() public editMode = false;
    // Default Size that we use later to config the Cropper and create a version from the Original Uploaded File.
    @Input() public size = '256x256';
    @Input() public uploadProgress = null;

}


/**
 * CoverImage - Component intended to be used within the `<mark6-cover>`.
 */
@Component({
    selector: 'mark6-avatar-image',
    template: `<img class="mark6-avatar-image" [src]="image || defaultImage" [alt]="alt"/>
`,
    styles: [`.mark6-avatar-image img{width:100%;display:block;-o-object-fit:cover;object-fit:cover;background-color:#404040}`],
    encapsulation: ViewEncapsulation.None
})
export class Mark6AvatarImageComponent {

    @HostBinding('class') public classes = 'mark6-avatar-image';

    @Input() public image;
    @Input() public alt;

    public defaultImage = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mN0+A8AAYUBQcxKiF0AAAAASUVORK5CYII=';

}


/**
 * CoverImage - Component intended to be used within the `<mark6-cover>`.
 */
@Component({
    selector: 'mark6-avatar-content',
    template: `<ng-content></ng-content>
`,
    styles: [``],
    encapsulation: ViewEncapsulation.None
})
export class Mark6AvatarContentComponent {

    @HostBinding('class') public classes = 'mark6-avatar-content';

}


/**
 * CoverImage - Component intended to be used within the `<mark6-cover>`.
 */
@Component({
    selector: 'mark6-avatar-content-edit',
    template: `<ng-content></ng-content>
`,
    styles: [``],
    encapsulation: ViewEncapsulation.None
})
export class Mark6AvatarContentEditComponent {

    @HostBinding('class') public classes = 'mark6-avatar-content-edit';

}


/**
 * CoverImage - Component intended to be used within the `<mark6-cover>`.
 */
@Component({
    selector: 'mark6-avatar-upload',
    template: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path d="M0 0h24v24H0z" fill="none"/>
    <path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3z"/>
</svg>
<ng-content select="span"></ng-content>
`,
    styles: [`.mark6-avatar-upload{padding:8px;z-index:300;position:absolute;display:flex;align-items:center;background-color:rgba(0,0,0,.64)}.mark6-avatar-upload svg{fill:#fff;width:24px;height:24px}@media only screen and (max-width:719px){.mark6-avatar-upload{justify-content:center}.mark6-avatar-upload svg{display:none}}@media only screen and (max-width:1279px){.mark6-avatar-upload{width:calc(100% - 16px);bottom:0;left:0}.mark6-avatar-upload svg{margin-right:8px}}.mark6-avatar-upload span{color:#fff}@media only screen and (min-width:1280px){.mark6-avatar-upload{width:92px;height:92px;top:50%;right:50%;justify-content:center;-webkit-transform:translate(50%,-50%);transform:translate(50%,-50%);opacity:0;outline:0;border:none;border-radius:50%;background-color:rgba(0,0,0,0);transition-duration:.4s;transition-timing-function:cubic-bezier(.25,.8,.25,1);transition-property:background-color,opacity}.mark6-avatar-upload svg{width:42px;height:42px}.mark6-avatar-upload span{display:none}}`],
    encapsulation: ViewEncapsulation.None
})
export class Mark6AvatarUploadComponent {

    @HostBinding('class') public classes = 'mark6-avatar-upload';

}
